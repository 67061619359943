import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { appRoutes } from './configs/app-routes-configs/app-routes.config';
import { AuthenticationGuard } from './guards/authentication.guard';

import { AvailableGuard, HomeModuleGuard } from './guards/available.guard';
import { IsAllowedGuard } from './guards/IsAllowed.guard';
import { ParameterGuard } from './guards/index.guard';
import { DashboardCaseComponent } from '@Layout/dashboard-case/dashboard-case.component';
// import { ModalsComponent } from './other/modals/modals.component';
import { ESystem } from '@Shared/models/index.model';
import { Config } from './configs/index.config';
import { AdminSetupGuard } from 'projects/evolutics-client-ui/src/app/Admin-setup/admin-setup.guard';
import { faqRoute } from 'projects/evolutics-admin-ui/src/app/client-libraries/client-libraries-routing.module';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo:inject(HomeModuleGuard).
  // },
  {
    path: '',
    canActivateChild: [HomeModuleGuard],
    children: [
      {
        path: 'login',
        redirectTo: appRoutes.auth.login.pub,
      },
      {
        path: 'update-password',
        redirectTo: appRoutes.auth.pub+'/update-password',
      },
      // {
      //   path: 'modals',
      //   component: ModalsComponent,
      // },
      {
        path: appRoutes.auth._,
        loadChildren: () => import('@Authentication/auth.module').then((m) => m.AuthModule),
      },
      {
        path: '',
        canActivate: [AvailableGuard],
        canActivateChild: [
          AuthenticationGuard,
          // HomeModuleGuard,
          IsAllowedGuard,
          ParameterGuard,
        ],
        component: DashboardCaseComponent,
        children: [
          {
            path: appRoutes.act._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-actuarial',
              system: ESystem.actuarial,
              title: 'Actuarial',
            },
            loadChildren: () =>
              import('./Actuarial/actuarial.module').then((m) => m.ActuarialModule),
          },
          {
            path: appRoutes.analytics._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-analytics',
              system: ESystem.analytics,
              title: 'Analytics',
            },
            loadChildren: () =>
              import('./Analytics/analytics.module').then((m) => m.AnalyticsModule),
          },
          // {
          //   path: appRoutes.cash._,
          //   canActivate: [AvailableGuard],
          //   data: {
          //     color: 'bg-cash',
          //     type: 'cash',
          //     system: ESystem.cash,
          //     title: 'Cash',
          //   },
          //   loadChildren: () =>
          //     import('./Cash/cash.module').then((m) => m.CashModule),
          // },
          { path: 'crms', redirectTo: appRoutes.crm._ },
          {
            path: appRoutes.crm._,
            canActivate: [AvailableGuard],
            data: { color: 'bg-crm', system: ESystem.crm, title: 'CRM' },
            loadChildren: () => import('./CRM/crm.module').then((m) => m.CRMSModule),
          },
          {
            path: appRoutes.dbcloner._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-dbcloner',
              system: ESystem.dBCloner,
              // type: 'dbcloner',
              title: 'DBCloner',
            },
            loadChildren: () => import('./Dbcloner/dbcloner.module').then((m) => m.DbclonerModule),
          },
          {
            path: appRoutes.finance._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-finance',
              system: ESystem.finance,
              title: 'Finance',
            },
            loadChildren: () => import('./Finance/finance.module').then((m) => m.FinanceModule),
          },
          {
            path: appRoutes.general._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-general',
              system: ESystem.general,
              title: 'General',
            },
            loadChildren: () => import('./General/general.module').then((m) => m.GeneralModule),
          },
          {
            path: appRoutes.health._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-health',
              system: ESystem.health,
              title: 'Health',
            },
            loadChildren: () => import('./Health/health.module').then((m) => m.HealthModule),
          },
          {
            path: appRoutes.human._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-primary',
              type: 'human',
              system: ESystem.human,
              title: 'Human',
            },
            loadChildren: () => import('./human/human.module').then((m) => m.HumanModule),
          },
          {
            path: appRoutes.life._,
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-primary',
              type: 'life',
              system: ESystem.life,
              title: 'Life',
            },
            loadChildren: () => import('./Life/life.module').then((m) => m.LifeModule),
          },
          {
            path: 'process',
            canActivate: [AvailableGuard],
            data: {
              color: 'bg-process',
              type: 'process',
              system: ESystem.process,
              title: 'Process',
            },
            loadChildren: () => import('./process/process.module').then((m) => m.ProcessModule),
          },
          {
            path: 'controllers',
            loadChildren: () =>
              import('./controllers/controllers.module').then((m) => m.ControllersModule),
          },
        ],
      },
      {
        path: Config.adminSetupRoute,
        canActivateChild: [AdminSetupGuard],
        loadChildren: () =>
          import('./Admin-setup/admin-setup.module').then((m) => m.AdminSetupModule),
      },
      {
        path: Config.alreadyOpenedRoute,
        loadChildren: () =>
          import('@Reusables/reusable-pages/page-not-found/page-not-found.module').then(
            (m) => m.PageNotFoundModule
          ),
        data: {
          color: 'bg-primary',
          config: {
            status: '402',
            message: 'An instance has already been opened. Please close this tab.',
          },
        },
      },
      {
        path: 'no-convert-scenario',
        loadChildren: () =>
          import('@Reusables/reusable-pages/page-not-found/page-not-found.module').then(
            (m) => m.PageNotFoundModule
          ),
        data: {
          color: 'bg-primary',
          title: 'Scenario Quote Conversion',
          config: {
            status: '401',
            message: 'This scenario quote is no longer valid.',
          },
        },
      },
      {
        path: 'help',
        redirectTo: 'faq',
      },
      faqRoute,
      {
        path: '',
        canActivateChild: [AuthenticationGuard],
        loadChildren: () =>
          import('@Reusables/reusable-pages/extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
    ],
  },
];
// console.log('routes', routes);

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
