import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ERole } from '@Authentication/auth-extras/authentication.interface';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { Config } from '../configs/index.config';

@Injectable({
  providedIn: 'root',
})
export class AdminSetupGuard  {
  constructor(public authS: AuthenticationService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(route, state);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.guard(childRoute, state);
  }
  private guard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // return true;
    // debugger
    if (environment.alreadyOpened) {
      this.router.navigateByUrl(Config.alreadyOpenedRoute);
      return false;
    } else if (this.authS.isLoggedin && environment.userIsSuperAdmin) {
      return true;
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: { r: location.href.replace(location.origin, '') },
      });
      return false;
    }
  }
}
