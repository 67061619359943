import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { Config } from '../configs/index.config';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    public authS: AuthenticationService,
    public router: Router,
    public route: ActivatedRoute
  ) {}
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard();
  }
  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard();
  }
  guard() {
    if (!environment.authenticate) return true;
    else if (environment.alreadyOpened && environment.isPrivate) {
      // debugger
      this.router.navigate(['/' + Config.alreadyOpenedRoute], { replaceUrl: true });
      return false;
    } else if (this.authS.isLoggedin) return true;
    else {
      this.router.navigate(['/auth/login'], {
        queryParams: { r: location.href.replace(location.origin, '') },
      });
      return false;
    }
  }
}
