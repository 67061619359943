import { enableProdMode } from '@angular/core';
import '@Shared/prototypes/prototypes';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { routes } from './app/app-routing.module';
import { sharedAppConfig } from 'projects/evolutics-shared-lib/src/lib/shared-app.config';

if (environment.production) enableProdMode();

function setVariables() {
  environment.sentryKey =
    'https://788180a42fae2ec8c97516faa22254d2@o4507594374250496.ingest.de.sentry.io/4507596109578320';
  return environment;
}
bootstrapApplication(AppComponent, sharedAppConfig(routes, setVariables())).catch((err) =>
  console.error(err),
);
