import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AppService } from '@Services/app.service';
import { SearchService } from '@Shared/components/search/search-extras/search.service';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { Config } from '@configs/index.config';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';

@Injectable({
  providedIn: 'root',
})
export class AvailableGuard {
  constructor(
    public appS: AppService,
    public router: Router,
    public sS: SearchService,
  ) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  private _guard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.data.system) {
      this.appS.system = route.data.system;
      this.appS.setCurrentTopMenu(route.data.system);
      this.sS.setSystemMenuIndex(route.data.system);
      this.appS.setMetaTheme();
    }
    if (!environment.activeSystems?.length) return true;
    else if (environment.activeSystems.includes(route.data.system || this.appS.system)) return true;
    else {
      this.router.navigateByUrl(this.appS.getFirstAvailableSystem.appRoute.pub);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class HomeModuleGuard {
  constructor(
    public appS: AppService,
    public authS: AuthenticationService,
    public router: Router,
    public searchS: SearchService,
  ) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  private _guard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // debugger
    if (state.url == '/' || state.url[1] == '?') {
      const routeConfig: NavigationExtras = {
        queryParamsHandling: 'merge',
        queryParams: state.root.queryParams,
      };
      if (environment.userIsSuperAdmin) this.router.navigate(['/' + Config.adminSetupRoute], routeConfig);
      else
        for (const menuItem of this.appS.topMenu)
          if (PermissionManager.selectFromSimpleMap(menuItem.id)?.viewAccess) {
            this.router.navigate([menuItem.link], routeConfig);
            return true;
          }
      this.router.navigate([this.appS.topMenu[0].link], routeConfig);
    }
    return true;
  }
  // getFirstHomeRoute():string {
  //   // debugger
  //   if (state.url == '/') {
  //     if (environment.userIsSuperAdmin) this.router.navigateByUrl('/' + Config.adminSetupRoute);
  //     else {
  //       // debugger
  //       for (const menuItem of this.appS.topMenu) {
  //         const mc = this.authS.usermenuConfigMap[this.authS.umS.mapKeySelector(menuItem)];
  //         if (mc?.viewAccess) {
  //           this.router.navigateByUrl(menuItem.link);
  //           return true;
  //         }
  //       }
  //     }
  //     this.router.navigateByUrl(this.appS.topMenu[0].link);
  //   }
  //   return true;
  // }
}
