import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ELanguage } from 'ets-fe-ng-sdk';

@Injectable()
export class AuthenticationInterceptorService implements HttpInterceptor {
  constructor(public router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // req.url
    //  debugger;
    if (
      environment.authenticate &&
      (req.url.startsWith(environment.apiBaseUrl) ||
        req.url.startsWith(environment.processMapBaseURL) ||
        req.url.startsWith(environment.messagingBaseURL))
    ) {
      const reqAuth = req.clone({
        url: req.url,
        headers: req.headers
          // .set('Content-Type', `application/json`)
          .set('Authorization', `${environment.token}`)
          .set('userCompanyCode', `${environment.userProfile?.users.primaryCompany}`)
          .set('language', `${environment.userProfile?.users.language || ELanguage.EN}`),
        withCredentials:
          environment.useAuthCookies &&
          req.method?.toUpperCase() != 'DELETE' &&
          req.method?.toUpperCase() != 'PUT',
      });
      return next.handle(reqAuth).pipe(
        catchError((event: HttpEvent<any>) => {
          if (event instanceof HttpErrorResponse && event.status == 401) {
            if (
              environment.token &&
              (event.url.startsWith(environment.apiBaseUrl) ||
                event.url.startsWith(environment.processMapBaseURL))
            ) {
              // debugger;
              console.error(`About to be loggeed out due to 401 `, event, req);
              // this.authS.logout();
              this.router.navigate(['/login']);
            }
          }
          throw event;
        }),
      );
    } else return next.handle(req);
  }
}
