<!--<div *ngIf="chS.isShown" class="chd">
  &lt;!&ndash; <img src="/assets/icons/chat-help-desk.svg"
   > &ndash;&gt;
  <svg alt="chat help desk icon" *ngIf="!chS.showOptions" class="pointer chd-icon" (click)="chS.toggleOptions()"
       width="456" height="510" viewBox="0 0 456 510" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="228" cy="255" r="228" [attr.fill]="appS.getCurrentSystemMetadata?.colourCode||circleFill"/>
    <path
      d="M244.298 379.856C244.004 386.634 241.941 391.643 238.11 394.884C234.575 398.42 230.597 400.188 226.177 400.188C222.052 400.188 218.221 398.567 214.685 395.326C211.149 392.379 208.939 387.665 208.055 381.182C207.761 353.484 207.466 325.197 207.171 296.32C207.171 267.444 207.024 239.157 206.729 211.459C208.203 205.565 210.56 201.146 213.801 198.199C217.042 195.252 220.726 193.779 224.851 193.779C228.681 193.779 232.217 195.105 235.459 197.757C238.994 200.409 241.499 204.534 242.972 210.133C243.267 237.831 243.414 266.118 243.414 294.994C243.709 323.871 244.004 352.158 244.298 379.856ZM203.635 139.414C203.635 132.932 205.698 127.481 209.823 123.061C213.948 118.346 218.958 115.989 224.851 115.989C230.744 115.989 235.753 118.346 239.878 123.061C244.298 127.481 246.508 132.932 246.508 139.414C246.508 145.602 244.298 151.053 239.878 155.768C235.753 160.188 230.744 162.398 224.851 162.398C218.958 162.398 213.948 160.188 209.823 155.768C205.698 151.053 203.635 145.602 203.635 139.414Z"
      fill="white"/>
  </svg>

  <i *ngIf="chS.showOptions" class="pointer chd-icon fa fa-close text-danger center" (click)="chS.toggleOptions()"></i>

  <div class="chd-options " *ngIf="chS.showOptions">
    <div class="glass rounded border p-2 mb-2">
      <h6 class="text-primary">{{'Chat Desk'|appTranslate|async}}</h6>
      <div class="">
        <input type="text" class="w-100 border rounded bg-transparent">
      </div>
    </div>
    <div class="glass rounded border p-2">
      <a href="{{chS.helpLink}}" class="d-block text-primary" target="_blank">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        {{'Help'|appTranslate|async}}</a>
    </div>
  </div>

</div>-->

@if (chS.isShown) {
  <button
    id="chat-circle"
    class="btn btn-raised"
    [matBadge]="chaBox.hidden ? totalPendingCounts() : null"
    [ngClass]="{ lower: uS.environment.wideScreen }"
    (click)="chaBox.hidden = !chaBox.hidden"
    cdkDrag>
    <div id="chat-overlay"></div>
    <i>
      <fa-icon [icon]="faCommentDots"></fa-icon>
    </i>
  </button>
}
<div class="chat-box" #chaBox hidden>
  <div class="chat-box-header">
    <div class="row justify-content-between">
      <div class="col-2 text-start">
        <span class="chat-box-toggle" [hidden]="showMsgValue" (click)="backMsg()">
          <i>
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </i>
        </span>
      </div>
      <div class="col">
        <span class="chat-box-toggle" (click)="chaBox.hidden = !chaBox.hidden">
          <i>
            <fa-icon [icon]="faCross"></fa-icon>
          </i>
        </span>
      </div>
    </div>

    <div class="row px-2 g-1 py-2">
      <div class="col-lg">
        <app-autocomplete
          name="user"
          [form]="form"
          type="select"
          valueField="username"
          labelType="uf"
          [showLabel]="false"
          [optionsFunc]="userSer.searchByName"
          placeholder="Search User" />
        <!-- <input type="search" class="form-control control-bg-gray" (click)="toggleUserListing($event)"
          placeholder="Search User"> -->
        <!-- <div [hidden]="userListing" class="user-result  shadow-sm mt-2"
          style="background:white; position: absolute;z-index: 99999999; overflow-y: scroll; max-height: 200px; ">
          <div class="row p-3">
            <div class="col">
              <div class="row g-0 p-0">
                <div class="col-3 px-2">
                  <img src="{{uS.environment.images.other.src}}" class="img-fluid circle-img" alt="">
                </div>
                <div class="col text-start d-flex align-items-center">
                  <div class="container">
                    <div class="row"><span class="text-black-50 smaller m-0">Sinaly Diarassouba</span></div>
                    <div class="row"><span class="text-success smaller m-0">Online</span></div>
                  </div>


                </div>
              </div>
              <div class="row">
                <div class="col">
                  <hr>
                </div>
              </div>
              <div class="row g-0">
                <div class="col-3 px-2">
                  <img src="{{uS.environment.images.other.src}}" class="img-fluid circle-img" alt="">
                </div>
                <div class="col text-start d-flex align-items-center">
                  <div class="container">
                    <div class="row"><span class="text-black-50 smaller m-0">Sinaly Diarassouba</span></div>
                    <div class="row"><span class="text-success smaller m-0">Online</span></div>
                  </div>


                </div>
              </div>
              <div class="row">
                <div class="col">
                  <hr>
                </div>
              </div>
              <div class="row g-0">
                <div class="col-3 px-2">
                  <img src="{{uS.environment.images.other.src}}" class="img-fluid circle-img" alt="">
                </div>
                <div class="col text-start d-flex align-items-center">
                  <div class="container">
                    <div class="row"><span class="text-black-50 smaller m-0">Sinaly Diarassouba</span></div>
                    <div class="row"><span class="text-success smaller m-0">Online</span></div>
                  </div>


                </div>
              </div>
              <div class="row">
                <div class="col">
                  <hr>
                </div>
              </div>
              <div class="row g-0">
                <div class="col-3 px-2">
                  <img src="{{uS.environment.images.other.src}}" class="img-fluid circle-img" alt="">
                </div>
                <div class="col text-start d-flex align-items-center">
                  <div class="container">
                    <div class="row"><span class="text-black-50 smaller m-0">Sinaly Diarassouba</span></div>
                    <div class="row"><span class="text-success smaller m-0">Online</span></div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-lg">
        <app-autocomplete
          [form]="form"
          name="group"
          [showLabel]="false"
          placeholder="Search Group"
          [options]="usersSer.getAllUsersGroupAndCount | functionCaller | async"
          labelField="userGroup"
          valueField="userGroup"></app-autocomplete>
        <!-- <input type="search" class="form-control control-bg-gray" (click)="toggleGroupListing($event)"
          placeholder="Search Group">
        <div [hidden]="groupListing" class="user-result  shadow-sm mt-2"
          style="background:white; position: absolute;z-index: 99999999; overflow-y: scroll; max-height: 200px; min-width: 200px;">
          <div class="row py-3">
            <div class="col">
              <div class="row g-0">
                <div class="col-4 px-2 text-black-50">
                  <fa-icon [icon]="faUser"></fa-icon>
                </div>
                <div class="col text-start d-flex align-items-center">
                  <div class="container">
                    <div class="row"><span class="text-black-50 smaller m-0">Admin</span></div>
                    <div class="row"><span class="text-primary smaller2 m-0">123 Members</span></div>
                  </div>


                </div>
              </div>
              <div class="row">
                <div class="col">
                  <hr>
                </div>
              </div>
              <div class="row g-0">
                <div class="col-4 px-2 text-black-50">
                  <fa-icon [icon]="faUser"></fa-icon>
                </div>
                <div class="col text-start d-flex align-items-center">
                  <div class="container">
                    <div class="row"><span class="text-black-50 smaller m-0">Admin</span></div>
                    <div class="row"><span class="text-primary smaller2 m-0">137 Members</span></div>
                  </div>


                </div>
              </div>
              <div class="row">
                <div class="col">
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row px-2 py-2 g-1">
      <div class="col">
        <a href="" class="w-100 text-white" title="Create Quotation">
          <fa-icon style="size: 3rem" [icon]="faClipboardMedical"></fa-icon>
        </a>
      </div>
      <div class="col">
        <a href="" class="w-100 text-white" title="Add Notes">
          <fa-icon style="size: 3rem" [icon]="faNote"></fa-icon>
        </a>
      </div>
      <div class="col">
        <a href="" class="w-100 text-white" title="Create Workflow Tasks">
          <fa-icon style="size: 3rem" [icon]="faList"></fa-icon>
        </a>
      </div>
      <div class="col">
        <a
          (click)="nS.open(); close(chaBox)"
          [matBadge]="messageService.unreadNotificationsCount()"
          class="w-100 text-white"
          title="Notifications">
          <fa-icon style="size: 3rem" [icon]="faBell"></fa-icon>
        </a>
      </div>
      <div class="col">
        <a
          routerLink="/life/admin-desk/evolutics/faq/index"
          (click)="close(chaBox)"
          class="w-100 text-white"
          title="Help">
          <fa-icon style="size: 3rem" [icon]="faQuestionCircle"></fa-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="chat-box-history" [hidden]="!showMsgValue">
    <div (click)="showMsg()" class="row g-0 bg-white p-1">
      <div class="col-3 px-2">
        <div class="profile-div">
          <img src="{{ uS.environment.images.other.src }}" class="img-fluid circle-img" alt="" />
          <i class="text-success">
            <fa-icon [icon]="faCircle"></fa-icon>
          </i>
        </div>
      </div>
      <div class="col text-start d-flex align-items-center">
        <div class="container">
          <div class="row"><span class="text-black smaller m-0">Sinaly Diarassouba</span></div>
          <div class="row">
            <span class="text-black-50 smaller2 m-0">
              You: Lorem ipsum dolor sit amet, consectetur adipisicing .
            </span>
          </div>
        </div>
      </div>
    </div>
    <div (click)="showMsg()" class="row g-0 bg-white p-1">
      <div class="col-3 px-2">
        <div class="profile-div">
          <img src="{{ uS.environment.images.other.src }}" class="img-fluid circle-img" alt="" />
          <i class="text-success">
            <fa-icon [icon]="faCircle"></fa-icon>
          </i>
        </div>
      </div>
      <div class="col text-start d-flex align-items-center">
        <div class="container">
          <div class="row"><span class="text-black smaller m-0">Sinaly Diarassouba</span></div>
          <div class="row">
            <span class="text-black-50 smaller2 m-0">
              You: Lorem ipsum dolor sit amet, consectetur adipisicing .
            </span>
          </div>
        </div>
      </div>
    </div>
    <div (click)="showMsg()" class="row g-0 bg-white p-1">
      <div class="col-3 px-2">
        <div class="profile-div">
          <img src="{{ uS.environment.images.other.src }}" class="img-fluid circle-img" alt="" />
          <i class="text-success">
            <fa-icon [icon]="faCircle"></fa-icon>
          </i>
        </div>
      </div>
      <div class="col text-start d-flex align-items-center">
        <div class="container">
          <div class="row"><span class="text-black smaller m-0">Sinaly Diarassouba</span></div>
          <div class="row">
            <span class="text-black-50 smaller2 m-0">
              You: Lorem ipsum dolor sit amet, consectetur adipisicing .
            </span>
          </div>
        </div>
      </div>
    </div>
    <div (click)="showMsg()" class="row g-0 bg-white p-1">
      <div class="col-3 px-2">
        <div class="profile-div">
          <img src="{{ uS.environment.images.other.src }}" class="img-fluid circle-img" alt="" />
          <i class="text-success">
            <fa-icon [icon]="faCircle"></fa-icon>
          </i>
        </div>
      </div>
      <div class="col text-start d-flex align-items-center">
        <div class="container">
          <div class="row"><span class="text-black smaller m-0">Sinaly Diarassouba</span></div>
          <div class="row">
            <span class="text-black-50 smaller2 m-0">
              You: Lorem ipsum dolor sit amet, consectetur adipisicing .
            </span>
          </div>
        </div>
      </div>
    </div>
    <div (click)="showMsg()" class="row g-0 bg-white p-1">
      <div class="col-3 px-2">
        <div class="profile-div">
          <img src="{{ uS.environment.images.other.src }}" class="img-fluid circle-img" alt="" />
          <i class="text-success">
            <fa-icon [icon]="faCircle"></fa-icon>
          </i>
        </div>
      </div>
      <div class="col text-start d-flex align-items-center">
        <div class="container">
          <div class="row"><span class="text-black smaller m-0">Sinaly Diarassouba</span></div>
          <div class="row">
            <span class="text-black-50 smaller2 m-0">
              You: Lorem ipsum dolor sit amet, consectetur adipisicing .
            </span>
          </div>
        </div>
      </div>
    </div>
    <div (click)="showMsg()" class="row g-0 bg-white p-1">
      <div class="col-3 px-2">
        <div class="profile-div">
          <img src="{{ uS.environment.images.other.src }}" class="img-fluid circle-img" alt="" />
          <i class="text-success">
            <fa-icon [icon]="faCircle"></fa-icon>
          </i>
        </div>
      </div>
      <div class="col text-start d-flex align-items-center">
        <div class="container">
          <div class="row"><span class="text-black smaller m-0">Sinaly Diarassouba</span></div>
          <div class="row">
            <span class="text-black-50 smaller2 m-0">
              You: Lorem ipsum dolor sit amet, consectetur adipisicing .
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-box-body" [hidden]="showMsgValue">
    <div class="chat-box-overlay"></div>

    <div class="chat-logs"></div>
    <!--chat-log -->
  </div>
  <div class="chat-input" [hidden]="showMsgValue">
    <form>
      <input type="text" id="chat-input" placeholder="Send a message..." />
      <button type="submit" class="chat-submit" id="chat-submit">
        <i class="">
          <fa-icon [icon]="faSend"></fa-icon>
        </i>
      </button>
    </form>
  </div>
</div>
